/* navbar.css */
.navbar {
    background-color: #789367!important;
    padding: 20px;
}

.navbar .navbar-brand,
.navbar .nav-link {
    color: #ffffff; /* Weiß für Kontrast */
}

.navbar .navbar-brand:hover,
.navbar .nav-link:hover {
    color: #cce6cc; /* Helles Grün beim Hover */
}

